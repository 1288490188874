import Vue from 'vue';
import Vuex from 'vuex';
import queryString from 'query-string';

Vue.use( Vuex );

export default new Vuex.Store(
    {
        state: {
            loading: false,
            step: 'personal',
            error: false,
            error_message: '',
            settings: {
                show_ac_types: false
            },
            static: {
                oid_prod: '00D1U000000u99q',
                oid_test: '00D7i0000008l2H'
            },
            gtm: {
                id: '',
                script: []
            },
            selections: {
                valid: false,
                terms_agree: false,
                name_first: null,
                name_last: null,
                email: null,
                phone: null,
                address: null,
                zip: null,
                interest_when: '',
                unit_size: '',
                utm_source: '',
                sqft: '1500',
                year_built: '1990',
                maintenance: '',
                maintenance_cost: 0,
                loan_downpayment: 0,
                loan_terms: 0,
                loan_amount: '6,000.00',
                loan_dividend: 0,
                loan_apr: 0,
                loan_payment: 0,
                external: null,
                debugEmail: 'bxr0w3n@fpl.com'
            },
            timeouts: [],
            datasets: {
                year_built: {
                    min: 1950
                },
                interest_when: [
                    { label: 'Within a week', value: 'Within a week' },
                    { label: 'Within a month', value: 'Within a month' },
                    { label: 'Within 3 months', value: 'Within the next 3 months' },
                    { label: 'Within a year', value: 'Within a year' }
                ],
                maintenance_frequencies: [
                    { label: 'Annually', value: 'Annual' },
                    { label: 'Bi-annually', value: 'Bi-Annual' }
                ],
                loan_down_payment_intervals: [
                    { label: '0%', value: 0 },
                    { label: '5%', value: 5 },
                    { label: '10%', value: 10 },
                    { label: '15%', value: 15 },
                    { label: '20%', value: 20 },
                    { label: '25%', value: 25 },
                    { label: '30%', value: 30 }
                ],
                loan_term_intervals: [
                    { label: '3 Years', value: 36 },
                    { label: '4 Years', value: 48 },
                    { label: '5 Years', value: 60 },
                    { label: '6 Years', value: 72 },
                    { label: '7 Years', value: 84 },
                    { label: '8 Years', value: 96 },
                    { label: '9 Years', value: 108 },
                    { label: '10 Years', value: 120 },
                ],
                loan_assumed_interest: 4,
                loan_costs: {
                    wall: 6000,
                    full: 10000
                },
                loan_metrics: {
                    'Annual': [
                        { term: 36, apr: 8.9, maintenance: 698.67, dividend: 31.4468 },
                        { term: 48, apr: 8.9, maintenance: 898.96, dividend: 40.1848 },
                        { term: 60, apr: 8.9, maintenance: 1084.98, dividend: 48.1734 },
                        { term: 72, apr: 8.9, maintenance: 1257.71, dividend: 55.4768 },
                        { term: 84, apr: 8.9, maintenance: 1418.20, dividend: 62.1540 },
                        { term: 96, apr: 8.9, maintenance: 1567.32, dividend: 68.2584 },
                        { term: 108, apr: 8.9, maintenance: 1705.85, dividend: 73.8394 },
                        { term: 120, apr: 8.9, maintenance: 1834.67, dividend: 78.9417 }
                    ],
                    'Bi-Annual': [
                        { term: 36, apr: 8.9, maintenance: 907.04, dividend: 31.4468 },
                        { term: 48, apr: 8.9, maintenance: 1169.29, dividend: 40.1848 },
                        { term: 60, apr: 8.9, maintenance: 1413.88, dividend: 48.1734 },
                        { term: 72, apr: 8.9, maintenance: 1641.97, dividend: 55.4768 },
                        { term: 84, apr: 8.9, maintenance: 1854.68, dividend: 62.1540 },
                        { term: 96, apr: 8.9, maintenance: 2053.23, dividend: 68.2584 },
                        { term: 108, apr: 8.9, maintenance: 2238.45, dividend: 73.8394 },
                        { term: 120, apr: 8.9, maintenance: 2411.35, dividend: 78.9417 }
                    ]
                }
            }
        },

        mutations: {
            set_value: function ( state, payload ) {
                if ( payload ) {
                    if ( payload.collection ) {
                        Vue.set( state[ payload.collection ], payload.attribute, payload.data );
                    } else {
                        Vue.set( state, payload.attribute, payload.data );
                    }
                }
            }
        },

        actions: {
            step_control: function ( context, payload ) {
                let step = payload.to;

                context.dispatch( 'gtm_run_script', { stage: context.state.step, action: 'depart' } );

                context.commit( 'set_value', { attribute: 'step', data: step } );

                this._vm.$gtm.trackView( step, 'currentPath' );

                if ( ( payload.element ) && ( payload.element_text ) ) {
                    context.dispatch( 'gtm_log_click', { element: payload.element, element_text: payload.element_text } );
                }

                if ( ( step === 'complete' ) && ( !context.state.loading ) ) {
                    context.commit( 'set_value', { attribute: 'loading', data: true } );

                    // dispatch salesforce data
                    context.dispatch( 'submit_to_salesforce' );
                } else {
                    context.commit( 'set_value', { attribute: 'loading', data: false } );
                }

                context.dispatch( 'gtm_run_script', { stage: step, action: 'arrive' } );
            },

            // log all input changes to GTM as form events
            gtm_log_value: function( context, payload ) {
                if ( ( payload ) && ( payload.element ) ) {
                    let dataLayer = {
                        'event': 'Form Input',
                        'category': 'Forms',
                        'action': 'formFieldsUpdated',
                        'label': 'app__' + context.state.step + '__' + payload.element,
                        'value': context.state.selections[ payload.element ],
                        'gtm.elementId': 'app__' + context.state.step + '__' + payload.element,
                        'gtm.elementTarget': 'app__' + context.state.step + '__' + payload.element,
                        'gtm.elementText': context.state.selections[ payload.element ],
                        'gtm.elementUrl': window.location.href + context.state.step,
                    };

                    if ( !payload.hide_element ) {
                        dataLayer[ 'gtm.element' ] = document.getElementById( payload.element );
                    }

                    if ( payload.delay_push ) {
                        if ( context.state.timeouts[ payload.element ] ) {
                            clearTimeout( context.state.timeouts[ payload.element ] );
                        }

                        context.state.timeouts[ payload.element ] = setTimeout(
                            () => {
                                dataLayer.value = context.state.selections[ payload.element ];

                                this._vm.$gtm.trackEvent( dataLayer );
                            },
                            2500
                        );

                    } else {
                        this._vm.$gtm.trackEvent( dataLayer );
                    }
                }
            },

            // log clicks to GTM as click events
            gtm_log_click: function( context, payload ) {
                if ( ( payload ) && ( payload.element ) ) {
                    let dataLayer = {
                        'event': 'gtm.click',
                        'category': 'Forms',
                        'action': 'formButtonClicked',
                        'label': 'app__' + context.state.step + '__' + payload.element,
                        'value': payload.element_text,
                        'gtm.elementId': 'app__' + context.state.step + '__' + payload.element,
                        'gtm.elementTarget': 'app__' + context.state.step + '__' + payload.element,
                        'gtm.elementText': payload.element_text,
                        'gtm.elementUrl': window.location.href + context.state.step,
                    };

                    if ( !payload.hide_element ) {
                        dataLayer[ 'gtm.element' ] = document.getElementById( payload.element );
                    }

                    this._vm.$gtm.trackEvent( dataLayer );
                }
            },

            // log any custom enter/leave events from the user-specified gtm-script attribute on the Vue root node
            // this is for tracking the user's flow between screens in the single page application flow
            gtm_run_script: function( context, payload ) {
                if ( ( context.state.gtm.script ) && ( context.state.gtm.script.length ) ) {
                    let index, line;

                    for ( index in context.state.gtm.script ) {
                        line = context.state.gtm.script[ index ]

                        if ( ( Object.prototype.hasOwnProperty.call( line, 'stage' ) ) &&
                             ( Object.prototype.hasOwnProperty.call( line, 'action' ) ) &&
                             ( Object.prototype.hasOwnProperty.call( line, 'data' ) ) &&
                             ( Object.prototype.hasOwnProperty.call( line.data, 'category' ) ) &&
                             ( Object.prototype.hasOwnProperty.call( line.data, 'label' ) ) &&
                             ( line.stage === payload.stage ) &&
                             ( line.action === payload.action ) ) {
                            this._vm.$gtm.trackEvent(
                                {
                                    //event: 'interaction', // Event type [default = 'interaction'] (Optional)
                                    category: line.data.category,
                                    action: payload.action,
                                    label: line.data.label,
                                    'gtm.element': payload.action,
                                    'gtm.elementUrl': window.location.href + line.stage
                                }
                            );
                        }
                    }
                }
            },

            submit_to_salesforce: function ( context ) {
                context.commit( 'set_value', { attribute: 'loading', data: true } );

                let payload = {
                    'oid': context.state.static.oid_prod,
                    'retURL': 'https://www.stress-freeac.com',
                    'lead_source': 'Website',
                    'recordType': '0121U000000lRh9',
                    'first_name': context.state.selections.name_first,
                    'last_name': context.state.selections.name_last,
                    '00N1U00000VJnil': context.state.selections.utm_source,
                    'email': context.state.selections.email,
                    'phone': context.state.selections.phone,
                    'street': context.state.selections.address,
                    'city': 'n/a',
                    'state': 'FL',
                    'zip': context.state.selections.zip,
                    '00N1U00000VOvOE': 'Yes',
                    '00N1U00000VOvOG': context.state.selections.interest_when,
//                    '00N1U00000VwBiQ': context.state.selections.loan_amount,
                    'Total_Price__c': context.state.selections.loan_amount,
//                    '00N1U00000VwBiT': context.state.selections.loan_terms,
                    'appl_Term__c': context.state.selections.loan_terms,
//                    '00N1U00000VwBiR': context.state.selections.maintenance,
                    'appl_Maintenance_Option__c': context.state.selections.maintenance,
//                    '00N1U00000VwBiS': context.state.selections.loan_payment,
                    'appl_Monthly_Payment__c': context.state.selections.loan_payment,
                    'debugEmail': 'bxr0w3n@fpl.com',
                    'debug': 1
                };

                if ( context.state.selections.external ) {
                    let key;

                    for ( key in context.state.selections.external ) {
                        payload.key = context.state.selections.external[ key ];
                    }
                }

                ( async () => {
                    // const rawResponse = await
                    // fetch('https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', {
                    const rawResponse = await fetch( 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', {
                        method: 'POST',
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                        },
                        body: queryString.stringify( payload )
                    } );

                    const content = await rawResponse.json();

                    console.log( content );
                } )();
                context.dispatch( 'step_control', { to: 'complete' } );
                context.commit( 'set_value', { attribute: 'loading', data: false } );
            },

            dismiss_errors: function ( context ) {
                context.commit( 'set_value', { attribute: 'error', data: false } );
                context.commit( 'set_value', { attribute: 'error_message', data: null } );
            }
        },

        modules: {},
    }
);
