<template>
    <div class="hvac__wrapper">
        <article class="hvac__container">
            <section class="hvac" v-bind:data-current-step-index="$store.state.step">
                <div class="hvac__loader" v-bind:class="{ 'hidden': !$store.state.loading }">
                    <div class="hvac__loader-inner">
                        <div class="hvac__loader-spinner"></div>

                        <h3>Communicating</h3>
                        <p>We are loading information from the server, please wait!</p>
                    </div>
                </div>
                <div class="hvac__notice" v-if="$store.state.error">
                    <h3>Uh oh!</h3>
                    <p>{{ $store.state.error_message }}</p>
                    <button type="button" v-on:click="$store.dispatch( 'dismiss_errors' );">Close</button>
                </div>

                <div class="hvac__inner">
                    <h1 class="hvac__title">Stress-Free A/C Program</h1>
                    <h2 class="hvac__subtitle">Presented by FPL Home</h2>
                    <h3 class="hvac__tagline">The coolest way to get a new A/C with no money down!</h3>

                    <FPLHVACStepsNavigation />
                    <div class="hvac__steps">
                        <FPLHVACStepsPersonal />
                        <FPLHVACStepsHouse />
                        <FPLHVACStepsFinancing />
                        <FPLHVACStepsComplete />

                        <FPLHVACStepsUnavailable />
                    </div>
                </div>
            </section>
        </article>

        <div class="hvac__legal">
            <p>FPL Home is an unregulated subsidiary of Florida Power & Light Company (FPL).  The Stress-Free A/C<sup>TM</sup> program is offered and provided by FPL Home, License No. CAC 1820493, and not FPL.</p>
        </div>
    </div>
</template>

<script>
import FPLHVACStepsPersonal from '@/components/FPLHVACStepsPersonal';
import FPLHVACStepsHouse from '@/components/FPLHVACStepsHouse';
import FPLHVACStepsComplete from '@/components/FPLHVACStepsComplete';
import FPLHVACStepsUnavailable from '@/components/FPLHVACStepsUnavailable';
import FPLHVACStepsNavigation from "@/components/FPLHVACStepsNavigation";
import FPLHVACStepsFinancing from "@/components/FPLHVACStepsFinancing";

export default {
    name: 'FPLHVACSteps',
    components: {
        FPLHVACStepsFinancing,
        FPLHVACStepsNavigation,
        FPLHVACStepsPersonal,
        FPLHVACStepsHouse,
        FPLHVACStepsComplete,
        FPLHVACStepsUnavailable,
    },
    mounted: function() {
        this.$store.dispatch( 'gtm_run_script', { stage: this.$store.state.step, action: 'arrive' } );
    }
};
</script>