<template>
    <div class="hvac__step" data-step-index="personal" v-if="$store.state.step === 'personal'">
        <div class="hvac__step-content">
            <h3 data-index="1">Tell us about you</h3>

            <fieldset class="hvac__fieldset">
                <div class="hvac__form-group">
                    <div class="hvac__form-columns">
                        <div class="hvac__form-column">
                            <label for="first_name" class="hvac__form-label hvac__form-label--floating">Your First Name</label>
                            <input type="text" id="name_first" name="name_first" placeholder="i.e., Mike" class="hvac__form-control" v-model="$store.state.selections.name_first" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'name_first' } )">
                        </div>
                        <div class="hvac__form-column">
                            <label for="last_name" class="hvac__form-label hvac__form-label--floating">Your Last Name</label>
                            <input type="text" id="name_last" name="name_last" placeholder="i.e., Smith" class="hvac__form-control" v-model="$store.state.selections.name_last" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'name_last' } )">
                        </div>
                    </div>
                </div>
                <div class="hvac__form-group">
                    <div class="hvac__form-columns">
                        <div class="hvac__form-column">
                            <label for="phone" class="hvac__form-label hvac__form-label--floating">Your Phone #</label>
                            <input type="text" id="phone" name="phone" placeholder="i.e., (407) 555-1212" class="hvac__form-control" v-model="$store.state.selections.phone" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'phone', delay_push: true } )">
                        </div>
                        <div class="hvac__form-column">
                            <label for="email" class="hvac__form-label hvac__form-label--floating">Your E-mail</label>
                            <input type="email" id="email" name="email" placeholder="i.e., you@gmail.com" class="hvac__form-control" v-model="$store.state.selections.email" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'email' } )">
                        </div>
                    </div>
                </div>
                <div class="hvac__form-group">
                    <div class="hvac__form-columns hvac__form-columns--reverse">
                        <div class="hvac__form-column">
                            <label for="address" class="hvac__form-label hvac__form-label--floating">Your Address</label>
                            <input type="text" id="address" name="address" placeholder="i.e., 123 Main Street, Miami, FL" class="hvac__form-control" v-model="$store.state.selections.address" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'address' } )">
                        </div>
                        <div class="hvac__form-column">
                            <label for="postal" class="hvac__form-label hvac__form-label--floating">Your ZIP Code</label>
                            <input type="text" id="zip" name="zip" placeholder="i.e., 33401" class="hvac__form-control" v-model="$store.state.selections.zip" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'zip' } )">
                        </div>
                    </div>
                </div>
            </fieldset>

            <div class="hvac__footer">
                <button type="button" id="personal__next" class="btn btn-primary" v-on:click="check_zip()" v-bind:disabled="( ( !$store.state.selections.zip ) || ( !$store.state.selections.address ) || ( !$store.state.selections.email ) || ( !this.email_valid() ) || ( !$store.state.selections.phone ) || ( !$store.state.selections.name_last ) || ( !$store.state.selections.name_first ) )">Continue <i class="fal fa-chevron-right" aria-hidden="true"></i></button>
            </div>

            <div class="hvac__notice">
                <h3>What happens next?</h3>
                <p>After you provide your name, street address and ZIP code, we will check to make sure you are in our service area, and then we will show you some sample financing options to upgrade your A/C in your home.</p>
                <p>By providing your phone number you agree to receive return calls from FPL Home and its contractors relating to your inquiry about the Stress-Free A/C program.  This can include calls through an automatic telephone dialing system or using a pre-recorded or artificial voice.  You consent to receive such calls for a period of one month despite any prior or future registration of your phone number on any state or federal "Do Not Call" list.  Depending on your phone plan, charges may apply.  You may opt-out of receiving calls by calling 833-437-5466, Option 2.</p>
            </div>
        </div>

        <FPLHVACStepsSidebar />
    </div>
</template>

<script>
import zips from '../assets/json/zip-codes.json';
import FPLHVACStepsSidebar from "@/components/FPLHVACStepsSidebar";

export default {
    name: 'FPLHVACStepsPersonal',
    components: {
        FPLHVACStepsSidebar
    },
    mounted: function() {
    },
    methods: {
        email_valid: function() {
            let email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return email_regex.test( this.$store.state.selections.email );
        },

        check_zip: function() {
            if ( ( zips ) && ( zips.length ) ) {
                for ( let counter = 0; counter < zips.length; counter++ ) {
                    if ( zips[ counter ].zip == this.$store.state.selections.zip ) {
                        this.$store.commit( 'set_value', { collection: 'selections', attribute: 'valid', data: true } );
                        this.$store.dispatch( 'step_control', { element: 'personal__next', element_text: 'Continue to House Information', to: 'house' } );
                        return;
                    }
                }

                this.$store.commit( 'set_value', { collection: 'selections', attribute: 'valid', data: false } );
                this.$store.dispatch( 'step_control', { element: 'personal__next', element_text: 'Continue to Out-of-area Screen', to: 'unavailable' } );
            }
        }
    }
};
</script>
