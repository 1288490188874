<template>
    <div class="hvac__step" data-step-index="financing" v-if="$store.state.step === 'financing'">
        <div class="hvac__step-content">
            <h3 data-index="3" class="mt-3">Estimate your payment</h3>
            <fieldset class="hvac__fieldset mt-1">
                <div class="hvac__form-columns">
                    <div class="hvac__form-column">
                        <div class="hvac__form-group">
                            <label class="hvac__form-label">Select Term</label>
                            <custom-slider id="loan_term_intervals" v-bind:values="$store.state.datasets.loan_term_intervals" v-model="$store.state.selections.loan_terms" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'loan_terms', hide_element: true, delay_push: true } )" />
                        </div>
                        <div class="hvac__form-group">
                            <label class="hvac__form-label">Select Maintenance</label>
                            <custom-slider id="maintenance_frequencies" v-bind:values="$store.state.datasets.maintenance_frequencies" v-model="$store.state.selections.maintenance" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'maintenance', hide_element: true, delay_push: true } )" />
                        </div>
                    </div>
                    <div class="hvac__form-column">
                        <div class="hvac__form-group hvac__form-group--numeric hvac__form-group--right">
                            <label for="amount" class="hvac__form-label">Est. Total Cost</label>
                            <input type="text" id="loan_amount" class="hvac__form-control-numeric" value="6,000.00" v-model="$store.state.selections.loan_amount" v-on:key="calculate_payment" v-on:change="calculate_payment(); $store.dispatch( 'gtm_log_value', { element: 'loan_amount' } )" />
                        </div>
                        <div class="hvac__form-group hvac__form-group--right">
                            <label class="hvac__form-label">Est. Monthly Payment<sup>&#42;</sup></label>
                            <p class="amount amount--large">{{ calculate_payment() | currency }}</p>
                        </div>
                    </div>
                </div>
                <div class="hvac__legal-terms">
                    <div class="hvac__legal-terms-text">
                        <p>Monthly Payments are based upon 8.9% APR.  Actual APR may vary.  Please see your Truth in Lending Disclosure that will accompany your agreement for your exact APR.</p>
                    </div>
                </div>
            </fieldset>
            <div class="hvac__footer">
                <button type="button" id="financing__prev" v-on:click="$store.dispatch( 'step_control', { element: 'financing__prev', element_text: 'Back to House Information', to: 'house' } )" class="btn btn-secondary"><i class="fal fa-chevron-left" aria-hidden="true"></i> Back</button>
                <button type="button" id="financing__next" v-on:click="$store.dispatch( 'step_control', { element: 'financing__next', element_text: 'Continue to Completion', to: 'complete' } )" class="btn btn-primary">Submit<i class="fal fa-chevron-right" aria-hidden="true"></i></button>
            </div>
        </div>
        <FPLHVACStepsSidebarFinancing />
    </div>
</template>
<script>
import CustomSlider from 'vue-custom-range-slider';
import FPLHVACStepsSidebarFinancing from "@/components/FPLHVACStepsSidebarFinancing";

export default {
    name: 'FPLHVACStepsFinancing',
    components: {
        FPLHVACStepsSidebarFinancing,
        CustomSlider
    },
    data: function() {
        return {
            amount: this.$store.state.datasets.loan_costs[ this.$store.state.selections.unit_size ]
        }
    },
    methods: {
        update_loan_amount: function() {
            this.amount = this.$store.state.datasets.loan_costs[ this.$store.state.selections.unit_size ];
        },

        get_financed_amount: function() {
            let total = parseFloat( this.$store.state.selections.loan_amount.toString().replace( /[^0-9.]/, '', ) );
            let down_payment = parseFloat( this.$store.state.selections.loan_downpayment );

            return total - ( total * ( down_payment / 100 ) );
        },

        set_loan_parameters: function() {
            if ( this.$store.state.datasets.loan_metrics[ this.$store.state.selections.maintenance ] ) {
                for ( let counter = 0; counter < this.$store.state.datasets.loan_metrics[ this.$store.state.selections.maintenance ].length; counter++ ) {
                    if ( this.$store.state.datasets.loan_metrics[ this.$store.state.selections.maintenance ][ counter ].term === this.$store.state.selections.loan_terms ) {
                        this.$store.commit( 'set_value', { collection: 'selections', attribute: 'maintenance_cost', data: this.$store.state.datasets.loan_metrics[ this.$store.state.selections.maintenance ][ counter ].maintenance } );
                        this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_apr', data: this.$store.state.datasets.loan_metrics[ this.$store.state.selections.maintenance ][ counter ].apr } );
                        this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_dividend', data: this.$store.state.datasets.loan_metrics[ this.$store.state.selections.maintenance ][ counter ].dividend } );

                        break;
                    }
                }
            }
        },

        calculate_payment: function() {
            this.set_loan_parameters();

            let total = parseFloat( this.$store.state.selections.loan_amount.toString().replace( /[^0-9.]/, '', ) );
            let down_payment = parseFloat( this.$store.state.selections.loan_downpayment );
            let principal = total - down_payment;
            let dividend = this.$store.state.selections.loan_dividend;
            let maintenance = this.$store.state.selections.maintenance_cost;
            let monthly = ( ( principal + maintenance ) / dividend );

            // save it for later
            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_payment', data: ( ( isFinite( monthly ) ) ? monthly : 0 ) } );

            return ( ( isFinite( monthly ) ) ? monthly : 0 );
        }
    }
};
</script>
