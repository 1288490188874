<template>
    <div class="hvac__navigation">
        <ul class="hvac__navigation-list">
            <li class="hvac__navigation-list-item" v-bind:class="{'hvac__navigation-list-item--current': $store.state.step === 'personal', 'hvac__navigation-list-item--trail': $store.state.step === 'house'}">
                <span>Step 1</span>
            </li>
            <li class="hvac__navigation-list-item" v-bind:class="{'hvac__navigation-list-item--current': $store.state.step === 'house', 'hvac__navigation-list-item--trail': $store.state.step === 'financing'}">
                <span>Step 2</span>
            </li>
            <li class="hvac__navigation-list-item" v-bind:class="{'hvac__navigation-list-item--current': $store.state.step === 'financing', 'hvac__navigation-list-item--trail': $store.state.step === 'complete' }">
                <span>Step 3</span>
            </li>
            <li class="hvac__navigation-list-item" v-bind:class="{'hvac__navigation-list-item--current': $store.state.step === 'complete'}">
                <span>Step 4</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FPLHVACStepsNavigation'
};
</script>