<template>
    <div class="steps">
        <FPLHVACSteps />
    </div>
</template>

<script>
import FPLHVACSteps from '@/components/FPLHVACSteps';
import jQuery from 'jquery';

export default {
    name: 'Home',
    components: {
        FPLHVACSteps,
    },
    mounted: function() {
        // gather external info from mount root
        let root = jQuery( '#fpl-hvac-root' );

        if ( root ) {
            let utm_source = root.data( 'utm-source' );
            let external = root.data( 'external' );
            let gtm_script = root.data( 'gtm-script' );

            if ( utm_source ) {
                this.$store.commit( 'set_value', { collection: 'selections', attribute: 'utm_source', data: utm_source } );
            }

            if ( external ) {
                this.$store.commit( 'set_value', { collection: 'selections', attribute: 'external', data: external } );
            }

            if ( gtm_script ) {
                this.$store.commit( 'set_value', { collection: 'gtm', attribute: 'script', data: gtm_script } );
            }
        }

        // set defaults
        if ( this.$store.state.datasets.loan_metrics.Annual ) {
            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'maintenance', data: 'annual' } );

            let slice = this.$store.state.datasets.loan_metrics.Annual[ this.$store.state.datasets.loan_metrics.Annual.length - 1 ];

            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_amount', data: '6,000.00' } );
            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_terms', data: slice.term } );
            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_dividend', data: slice.dividend } );
            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'maintenance_cost', data: slice.maintenance } );
            this.$store.commit( 'set_value', { collection: 'selections', attribute: 'loan_apr', data: slice.apr } );
        }
    }
};
</script>
