<template>
    <div class="hvac__step" data-step-index="house" v-if="$store.state.step === 'house'">
        <div class="hvac__step-content">
            <h3 data-index="2">Tell us about your home</h3>

            <fieldset class="hvac__fieldset">
                <div class="hvac__form-columns">
                    <div class="hvac__form-column">
                        <div class="hvac__form-group" v-bind:class="{ 'hidden': !$store.state.settings.show_ac_types }">
                            <label class="hvac__form-label">Unit Type</label>

                            <input type="radio" id="unit_hvac_wall" name="unit" value="wall" class="hvac__form-control hvac__form-control--unit" checked v-model="$store.state.selections.unit_size" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'unit_size', hide_element: true, delay_push: true } )">
                            <label for="unit_hvac_wall" class="hvac__form-label hvac__form-label--unit"><i class="hvac__form-label--unit-wall" aria-hidden="true"></i> Wall A/C</label>

                            <input type="radio" id="unit_hvac_full" name="unit" value="full" class="hvac__form-control hvac__form-control--unit" v-model="$store.state.selections.unit_size" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'unit_size', hide_element: true, delay_push: true } )">
                            <label for="unit_hvac_full" class="hvac__form-label hvac__form-label--unit"><i class="hvac__form-label--unit-full" aria-hidden="true"></i> HVAC</label>
                        </div>

                        <div class="hvac__form-group">
                            <label for="year_built" class="hvac__form-label hvac__form-label--range" data-range-start="1950" v-bind:data-range-end="new Date().getFullYear()">Select Year Built</label>
                            <custom-slider id="year_built" v-bind:min="$store.state.datasets.year_built.min.toString()" v-bind:max="new Date().getFullYear().toString()" step="1" v-model="$store.state.selections.year_built" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'year_built', hide_element: true } )" />
                        </div>

                        <div class="hvac__form-group">
                            <label for="sqft" class="hvac__form-label hvac__form-label--range" data-range-start="500" data-range-end="4000+">Select Sq. Ft.</label>
                            <custom-slider id="sqft" min="500" max="3950" step="150" v-model="$store.state.selections.sqft" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'sqft', hide_element: true } )" />
                        </div>
                    </div>
                    <div class="hvac__form-column hvac__form-column--slider-adjust">
                        <div class="hvac__form-group">
                            <label class="hvac__form-label">Replacement</label>
                            <p>When do you plan to replace your A/C system?</p>

                            <div class="hvac__form-interest">
                                <div class="hvac__form-interest-element" v-for="( interest, interest_index ) in $store.state.datasets.interest_when" v-bind:key="interest_index">
                                    <input type="radio" v-bind:value="interest.value" v-bind:id="'interest_when_' + interest_index" name="interest_when" class="hvac__form-control" checked v-model="$store.state.selections.interest_when" v-on:change="$store.dispatch( 'gtm_log_value', { element: 'interest_when', hide_element: true } )">
                                    <label v-bind:for="'interest_when_' + interest_index" class="hvac__form-label hvac__form-label--interest"><i v-bind:data-type="interest.label" aria-hidden="true"></i> {{ interest.label }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <div class="hvac__footer">
                <button type="button" id="house__prev" v-on:click="$store.dispatch( 'step_control', { element: 'house__prev', element_text: 'Back to Personal Information',  to: 'personal' } )" class="btn btn-secondary"><i class="fal fa-chevron-left" aria-hidden="true"></i> Back</button>
                <button type="button" id="house__next" v-on:click="$store.dispatch( 'step_control', { element: 'house__next', element_text: 'Continue to Financing Information', to: 'financing' } )" class="btn btn-primary" v-bind:disabled="( ( !$store.state.selections.year_built ) || ( !$store.state.selections.sqft ) || ( !$store.state.selections.interest_when ) )">Next <i class="fal fa-chevron-right" aria-hidden="true"></i></button>
            </div>
        </div>

        <FPLHVACStepsSidebarHouse />
    </div>
</template>

<script>
import CustomSlider from 'vue-custom-range-slider';
import FPLHVACStepsSidebarHouse from "@/components/FPLHVACStepsSidebarHouse";

export default {
    name: 'FPLHVACStepsHouse',
    components: {
        FPLHVACStepsSidebarHouse,
        CustomSlider
    }
};
</script>
