<template>
    <div class="hvac__step" data-step-index="complete">
        <div class="hvac__step-content">
            <h3 data-index="4">Complete!</h3>

            <p>Thank you for your interest in the Stress-Free A/C Program.</p>
            <p>We'll get in touch shortly to discuss next steps.</p>
        </div>

        <FPLHVACStepsSidebar />
    </div>
</template>

<script>
import FPLHVACStepsSidebar from "@/components/FPLHVACStepsSidebar";

export default {
    name: 'FPLHVACStepsComplete',
    components: {
        FPLHVACStepsSidebar
    }
};
</script>