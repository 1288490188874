<template>
    <div class="hvac__step" data-step-index="unavailable">
        <div class="hvac__step-content">
            <h3 data-index="!">Our services are unavailable in your area <i class="fal fa-frown" aria-hidden="true"></i></h3>

            <p>Sorry, but the ZIP code you entered is not within our service area.</p>
            <p>We're constantly adding new service areas, so check back often.  If you have questions, please contact us.</p>

            <div class="hvac__footer">
                <button v-on:click="$store.dispatch( 'step_control', { to: 'personal' } )" type="button" class="btn btn-secondary"><i class="fal fa-chevron-left" aria-hidden="true"></i> Back</button>
                <a href="https://fpl.com/" class="btn btn-primary">Visit Our Site <i class="fal fa-chevron-right" aria-hidden="true"></i></a>
            </div>
        </div>

        <FPLHVACStepsSidebar />
    </div>
</template>

<script>
import FPLHVACStepsSidebar from "@/components/FPLHVACStepsSidebar";

export default {
    name: 'FPLHVACStepsUnavailable',
    components: {
        FPLHVACStepsSidebar
    }
};
</script>