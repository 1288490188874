import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCurrencyFilter from 'vue-currency-filter';
import VueGtm from '@gtm-support/vue2-gtm';
import zips from './assets/json/zip-codes.json';

Vue.config.productionTip = false;

Vue.use( zips );
Vue.use(
    VueGtm,
    {
        id: 'GTM-MKKW8B8',
        //id: 'GTM-MMHQ3W9',
        defer: false,
        compatibility: false,
        enabled: true,
        debug: true,
        loadScript: true,
    }
);
Vue.use(
    VueCurrencyFilter,
    {
        symbol: '$',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: undefined,
    }
);

new Vue(
    {
        router,
        store,
        render: ( h ) => h( App ),
    }
).$mount( "#app" );
