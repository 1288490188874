<template>
    <div class="hvac__step-sidebar">
        <aside class="hvac__step-sidebar-block">
            <h3>Need more information?</h3>
            <p>Call us at <a href="tel:833-437-5466">833-437-5466</a>.</p>
            <div class="row">
                <div class="column">
                    <a href="https://www.fplhome.com" targets="_blank"><img src="@/assets/images/FPL_Home_logo.svg" alt="FPL Home"></a>
                </div>
                <div class="column">
                    <a href="https://www.stress-freeac.com" target="_blank"><img src="@/assets/images/SFAC_logo.svg" alt="Stress-Free AC"></a>
                </div>
            </div>

        </aside>
    </div>
</template>

<script>
export default {
    name: 'FPLHVACStepsSidebar'
};
</script>
